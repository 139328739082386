import Page from '../containers/Page';
import styled from 'styled-components/native';
import { useIsFocused } from '@react-navigation/native';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useEffect } from 'react';
import CustomModal from '../presentational/Modal';
import { vhToDp, vwToDp } from '../../utilities/general';
import DatePicker from '../containers/DatePicker';
import StepForm from '../containers/StepForm/index';
import AreYouSureRemoveDocumentModal from '../containers/Modal/AreYouSureRemoveDocumentModal';

const Container = styled.View`
  padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

export default function EditToolPage({ route, navigation, ...props }) {
  const isFocused = useIsFocused();

  const {
    tools: {
      nextCalibrationDateCalendarVisible,
      nextPMIDateCalendarVisible,
      reservationCalendarVisible,
      loadTestDatePickerActive,
      input: toolInput,
    },
    inspections: { input, list },
  } = useStoreState((state) => state);

  const {
    getToolThunk,
    setIsEditableToolsAction,
    getToolInspectionHistoryThunk,
    getReservationHistoryThunk,
    cancelReservationToolThunk,
    getToolStatusThunk,
    getStorageLocationsThunk,
    getLocationsThunk,
    getInspectionThunk,
    getDocumentsListThunk,
    removeDocumentThunk,
    setDocumentListAction,
    setEditStepCountAction,
    getInspectionsThunk,
    upsertInspectionsThunk,
    setInspectionsShowModalAction,
    setInspectionsListAction,
    upsertToolTransferThunk,
    setToolTransferInputAction,
    setTransferModalAction,
    setDocumentToAddListAction,
  } = useStoreActions((actions) => actions);

  useEffect(() => {
    return () => {
      setEditStepCountAction(1);
    };
  }, []);

  useEffect(() => {
    if (isFocused) {
      setInspectionsListAction([]);
      setDocumentListAction([]);
      setIsEditableToolsAction(false);
      getToolThunk(route.params.id);
      getToolInspectionHistoryThunk(route.params.id);
      getStorageLocationsThunk();
      getLocationsThunk();
      getInspectionThunk();
      getInspectionsThunk();
      getToolStatusThunk();
      getDocumentsListThunk();
      getReservationHistoryThunk();
      setDocumentToAddListAction([]);
    }
  }, [isFocused]);

  return (
    <Page.Protected {...props}>
      <Container>
        <AreYouSureRemoveDocumentModal
          confirmationPhrase='Are you sure you want to delete this document?'
          onSubmit={() => {
            removeDocumentThunk();
          }}
        />
        <CustomModal.AreYouSure
          confirmationPhrase='Are you sure you want to cancel this reservation?'
          onSubmit={() => {
            cancelReservationToolThunk();
          }}
        />
        <DatePicker.LoadTest />

        <DatePicker.NextCalibration />
        <DatePicker.PressureTest />
        <DatePicker.CyclicTest />
        <DatePicker.FunctionalTest />
        {nextPMIDateCalendarVisible == true && <DatePicker.NextPMI />}
        {reservationCalendarVisible && <DatePicker.Reservation />}

        <CustomModal.InspectionHistory />
        <CustomModal.InspectionQuestions />
        <CustomModal.Barcode />
        <CustomModal.ToolHistory />
        <CustomModal.AddInspection
          onSubmit={async () => {
            var index = list.findIndex(
              (x) => x.inspectionTypeId === input.inspectionTypeId
            );
            if (index > -1) {
              list[index] = { ...input, id: list[index].id };
              await upsertInspectionsThunk({ input: list[index] });
            } else {
              await upsertInspectionsThunk({ input });
            }
            await getInspectionsThunk();
            setInspectionsShowModalAction(false);
          }}
        />
        <CustomModal.TransferTool
          onSubmit={async () => {
            await upsertToolTransferThunk();
            setToolTransferInputAction({
              id: null,
              fromtoollocationid: null,
              totoollocationid: null,
              trackingnumber: null,
              amount: null,
            });
            setTransferModalAction(false);
          }}
        />

        <h3
          style={{
            color: props.color || '#00205b',
            fontFamily: 'Barlow_600SemiBold',
            textAlign: 'center',
          }}
        >{`${toolInput.nomenclature} (${toolInput.partNumber})`}</h3>
        <StepForm.EditToolStep navigation={navigation} />
      </Container>
    </Page.Protected>
  );
}
