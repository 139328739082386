import NextCalibrationDatePicker from './Tools/NextCalibrationDatePicker';
import { View as DatePicker } from 'react-native';
import NextPMIDatePicker from './Tools/NextPMIDatePicker';
import ReservationDatePicker from './Tools/ReservationDatePicker';
import WorkPackageReservationDatePicker from './WorkPackageReservationDatePicker';
import LoadTestDueDatePicker from './Tools/LoadTestDueDatePicker';
import PressureTestDatePicker from './Tools/PressureTestDatePicker';
import CyclicTestDatePicker from './Tools/CyclicTestDatePicker';
import FunctionalTestDatePicker from './Tools/FunctionalTestDatePicker';

DatePicker.NextCalibration = NextCalibrationDatePicker;
DatePicker.NextPMI = NextPMIDatePicker;
DatePicker.Reservation = ReservationDatePicker;
DatePicker.WorkPackageReservation = WorkPackageReservationDatePicker;
DatePicker.PressureTest = PressureTestDatePicker;
DatePicker.CyclicTest = CyclicTestDatePicker;
DatePicker.FunctionalTest = FunctionalTestDatePicker;

DatePicker.LoadTest = LoadTestDueDatePicker;

export default DatePicker;
