import Page from '../containers/Page';
import styled from 'styled-components/native';
import { useIsFocused } from '@react-navigation/native';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useEffect } from 'react';
import DatePicker from '../containers/DatePicker';

import { vhToDp, vwToDp } from '../../utilities/general';
import { Platform } from 'react-native';
import DateTimePickerModal from 'react-native-modal-datetime-picker';
import moment from 'moment';
import StepForm from '../containers/StepForm/index';
import CustomModal from '../containers/Modal/index';
import AreYouSureRemoveDocumentModal from '../containers/Modal/AreYouSureRemoveDocumentModal';

const Container = styled.View`
  padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

export default function AddToolPage({ route, navigation, ...props }) {
  const isFocused = useIsFocused();
  const {
    tools: {
      nextCalibrationDateCalendarVisible,
      nextPMIDateCalendarVisible,
      reservationCalendarVisible,
      loadTestDatePickerActive,
    },
    documents,
    removeDocumentInput,
    inspections: { list, input },
  } = useStoreState((state) => state);

  const {
    resetToolsInputAction,
    resetDocumentAction,
    setIsEditableToolsAction,
    setNextCalibrationCalendarVisible,
    setToolsInputAction,
    setNextPMICalendarVisible,
    getStorageLocationsThunk,
    getLocationsThunk,
    getInspectionThunk,
    getToolStatusThunk,
    setDocumentListAction,
    setExtraDocumentListAction,
    setInspectionsListAction,
    setInspectionsShowModalAction,
    setDocumentToAddListAction,
  } = useStoreActions((actions) => actions);

  useEffect(() => {
    if (isFocused) {
      resetToolsInputAction();
      resetDocumentAction();
      getStorageLocationsThunk();
      getLocationsThunk();
      getInspectionThunk();
      getToolStatusThunk();
      setDocumentListAction([]);
      setExtraDocumentListAction([]);
      setIsEditableToolsAction(true);
      setInspectionsListAction([]);
      setDocumentToAddListAction([]);
    }
  }, [isFocused]);

  return (
    <Page.Protected {...props}>
      <Container>
        <AreYouSureRemoveDocumentModal
          confirmationPhrase='Are you sure you want to delete this document?'
          onSubmit={() => {
            setDocumentListAction(
              documents.filter((doc) => doc.id !== removeDocumentInput.id)
            );
          }}
        />
        <DatePicker.PressureTest />
        <DatePicker.CyclicTest />
        <DatePicker.FunctionalTest />
        {loadTestDatePickerActive == true && Platform.OS == 'web' && (
          <DatePicker.LoadTest />
        )}

        {nextCalibrationDateCalendarVisible == true && Platform.OS == 'web' && (
          <DatePicker.NextCalibration />
        )}
        {nextCalibrationDateCalendarVisible == true && Platform.OS == 'ios' && (
          <DateTimePickerModal
            isVisible={true}
            mode='date'
            onConfirm={(date) => {
              setToolsInputAction({
                nextCalibrationTestDueDate: moment(date).format('MM/DD/YYYY'),
              });
              setNextCalibrationCalendarVisible(false);
            }}
            onCancel={() => {
              setNextCalibrationCalendarVisible(false);
            }}
            minimumDate={new Date()}
            maximumDate={new Date(2025, 12, 31)}
          />
        )}
        {nextPMIDateCalendarVisible == true && Platform.OS == 'web' && (
          <DatePicker.NextPMI />
        )}
        {nextPMIDateCalendarVisible == true && Platform.OS == 'ios' && (
          <DateTimePickerModal
            isVisible={true}
            mode='date'
            onConfirm={(date) => {
              setNextPMICalendarVisible(false);

              setToolsInputAction({
                nextPMIDate: moment(date).format('MM/DD/YYYY'),
              });
            }}
            onCancel={() => {
              setNextPMICalendarVisible(false);
            }}
          />
        )}
        {reservationCalendarVisible && <DatePicker.Reservation />}
        <CustomModal.Barcode />
        <CustomModal.AddInspection
          onSubmit={async () => {
            var index = list.findIndex(
              (x) => x.inspectionTypeId === input.inspectionTypeId
            );
            if (index > -1) {
              list[index] = input;
              setInspectionsListAction([...list]);
            } else {
              setInspectionsListAction([...list, input]);
            }
            setInspectionsShowModalAction(false);
          }}
        />
        <StepForm.AddToolStep navigation={navigation} />
      </Container>
    </Page.Protected>
  );
}
