import React, { useState } from 'react';
import DateTimePickerComponent from '../../../presentational/DateTimePicker';
import { useStoreActions, useStoreState } from 'easy-peasy';
import moment from 'moment';
import { Platform } from 'react-native';

export default function NextCalibrationDatePicker(visible) {
  const { nextCalibrationDateCalendarVisible, input } = useStoreState(
    (state) => state.tools
  );

  const { setNextCalibrationCalendarVisible, setToolsInputAction } =
    useStoreActions((actions) => actions);

  const [date, setDate] = useState(new Date(Date.now()));

  return (
    <DateTimePickerComponent
      visible={nextCalibrationDateCalendarVisible}
      onChange={(date) => {
        if (Platform.OS === 'web') {
          setNextCalibrationCalendarVisible(
            !nextCalibrationDateCalendarVisible
          );

          setToolsInputAction({
            nextCalibrationTestDueDate: moment(date).format('MM/DD/YYYY'),
          });
        } else {
          console.log(date);
          setDate(date);
          setToolsInputAction({
            nextCalibrationTestDueDate: moment(date).format('MM/DD/YYYY'),
          });
        }
      }}
      value={date}
      onBackdropPress={() => setNextCalibrationCalendarVisible(false)}
    />
  );
}
